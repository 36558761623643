.emo-img-legal {
  width: 100%;
  height: 48vh;
  object-fit: cover;
}

.section-legal {
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $color-primary;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-secondary;
    }
  }
}

