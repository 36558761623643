#ueberblick {

  .ueberblick-trenner {
    width: 1px;
    height: auto;
    margin: 0 30px 0;
    display: block;
    background: $color-primary;
    content: "";
    flex-shrink: 0;
  }
}