// main navigation
.nav-wrap {
  padding: 40px 0 20px;
}

.header-top {
  margin-bottom: -5px;
}

nav.mainnav {
  //@include font-size($fontsize45);
  text-transform: uppercase;
  font-weight: bold;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  ul li {
    display: block;
    padding: 0;
    margin: 0;
    background: none;

    &.nav-div {
      width: 2px;
      height: 16px;
      margin: 5px 10px 0 10px;
      display: block;

      @media only screen and (max-width: 920px) {
        margin: 5px 5px 0 5px;
      }
    }

  }
}

nav.main-nav {
  a {
    // level 1
    &.nav-lv1 {
      color: $color-primary;
      transition: color 0.3s ease-in-out;

      &.act, &:hover {
        color: $color-secondary;
      }
    }
  }

  .nav-div {
    background: $color-black;
  }
}


// navigation fixed

.nav-fixed {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;

  .nav-wrap {
    padding: 0;
    background: $color-primary-transparent;
  }

  .wrapper {
    padding-top: 10px;
    padding-bottom: 10px;

    ul {
      justify-content: space-between;
    }
  }

  a {
    color: $color-grey;
    transition: color 0.3s ease-in-out;

    &.act, &:hover {
      color: #fff;
    }

  }

  .nav-div {
    background: $color-grey;
  }

}


// mobile nav
.mobile-nav {
  display: none;
}

@media only screen and (max-width: $breakpoint-navi) {

  .main-nav {
    display: none;
  }


  .header-top {

    // mobile navigation (header top)
    .mobile-nav {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 28px;
      cursor: pointer;
      z-index: 200;
      transform: translate(-50%, -50%);


      img {
        width: 100%;
      }

      .close {
        display: none;
      }

      &.act {
        .close {
          display: block;
        }

        .open {
          display: none;
        }
      }
    }

    .nav-fixed {
      position: absolute;
      top: 70px;
    }

    nav.mainnav ul {
      display: block;

      li a.nav-lv1 {
        display: block;
        padding: 4px 0;
      }

      li.nav-div {
        width: 100%;
        height: 1px;
        margin: 0;
      }
    }

  }

  // fixed state
  .header-top {
    &.fixed {
      display: none;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000;

      .mobile-nav {
        top: 10px;
      }

    }
  }

  // navigation
  .nav-wrap.emopart {
    display: none;
  }
}


@media only screen and (max-width: $breakpoint-mobile) {

  // navigation
  .header-top {

    nav.mainnav ul li a.nav-lv1 {
      padding: 5px 0;
    }
  }
}

//Logo in navigation
.nav-logo {
  width: 25px;
  height: auto;
  flex-shrink: 0;
}

.navi-flex {
  display: flex;
  justify-content: end;

  @media (max-width: $breakpoint-navi){
    display: block;
  }
}