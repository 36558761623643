footer {
  // global footer styles
  width: 100%;
  height: auto;
  background-color: $color-primary;
  color: $color-white;

  h1, h2, h3, h4, h5, h6, p, a, li {
    line-height: 1.3;
  }

  ul li {
    list-style: none;
  }


  .footer-content-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5% 0 0 0;

    @media (max-width: $breakpoint-smallest) {
      flex-direction: column;
    }
  }

  // left/first footer box
  .footer-left-box {
    .svg-square {
      align-self: flex-start;

      @media (max-width: $breakpoint-small) {
        margin-left: -2%;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 140px;
      }
    }

    .address {
      //margin-left: 50px;
      font-weight: 800;
      text-transform: uppercase;

      @media (max-width: $breakpoint-small) {
        margin-left: 0;
        margin-top: 20px;
      }

    }
  }

  .footer-left-box.content-box {
    justify-content: flex-start;
  }

  // Footer Box Trenner
  .footer-box-trenner {
    width: 1px;
    height: auto;
    margin: 15px 30px 0;
    display: block;
    background: $color-white;
    flex-shrink: 0;
  }

  .footer-right-box {
    align-items: center;

    @media (max-width: $breakpoint-small) {
      align-items: flex-start;

      ul {
        margin-bottom: 20px;
      }
    }

    .footer-navi {
      text-transform: uppercase;
      font-weight: 800;
    }
  }


  // bottom/third footer box
  .footer-bottom-box {
    padding-bottom: 5%;
    margin-top: 5%;

    ul {
      display: flex;

      .footer-list-trenner {
        width: 1px;
        height: 16px;
        margin: 4px 10px;
        display: block;
        background: $color-white;

      }
    }

  }
}

