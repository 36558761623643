#location {

  .margin-bottom {
    margin-bottom: 30px;
  }

  // ------------ offset for trenner-heading - START

  margin-top: 50px;

  @media (max-width: 787px) {
    margin-top: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 70px;
  }

  .img-location {
    margin-top: 20px;
  }
}