#basic-infos {

  .main-wrap {
    position: relative;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      margin-bottom: 20px;
      @media (max-width: $breakpoint-smaller) {
        flex-direction: column;

        p:nth-child(2) {
          padding-bottom: 3%;
        }
      }


      p:first-child {
        // gleicher Abstand wie der Button
        width: 150px;
        text-transform: uppercase;
        color: $color-primary;
        font-weight: 600;
        flex-shrink: 0;
      }

      p {
        margin-bottom: 2%;
      }
    }
  }

  .img-eckdaten {
    margin: 5% 5% 0 -17%;
    width: 50%;
    object-fit: cover;
    border: 20px solid $color-white;

    @media (max-width: $breakpoint-medium) {
      width: 70%;
      margin: 4% 0 4% 0;
      border: 15px solid $color-white;
    }
    @media (max-width: $breakpoint-smallest) {
      border: 10px solid $color-white;
    }
  }

  .svg-square {
    position: absolute;
    top: 100px;

    @media (max-width: $breakpoint-medium) {
      right: 0;
    }
  }

  .button {
    // gleicher Abstand wie das erste List Item
    margin: 20px 0 10px 210px;

    @media (max-width: $breakpoint-smaller) {
      margin: 20px 0 10px 0;
    }
  }

  .eckdaten-wrapper {
    display: flex;
    margin: 2% 10% 5% 10%;
    padding: 0 15px;

    @media (max-width: $breakpoint-large) {
      margin-right: 7.5%;
    }

    @media (max-width: $breakpoint-medium) {
      margin: 0 5%;
      flex-direction: column;
    }

    @media (max-width: $breakpoint-mobile) {
      margin: 0 2.5%;
    }


  }

}