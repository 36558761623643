/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-sans-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Light'), local('NunitoSans-Light'),
  url('../fonts/nunito-sans-v5-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-600 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-sans-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
  url('../fonts/nunito-sans-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-sans-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
  url('../fonts/nunito-sans-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-sans-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
  url('../fonts/nunito-sans-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-700.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-800 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/nunito-sans-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
  url('../fonts/nunito-sans-v5-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

/* nunito-sans-900 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-sans-v5-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Nunito Sans Black'), local('NunitoSans-Black'),
  url('../fonts/nunito-sans-v5-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-sans-v5-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-sans-v5-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-sans-v5-latin-900.svg#NunitoSans') format('svg'); /* Legacy iOS */
}

h1, h2, h3, h4, h5, h6, p, a, ul, li, form, .fancybox-caption__body {
  margin: 0;
  padding: 0;
  font-family: Nunito Sans, sans-serif;
  line-height: 1.5;
}

a {
  color: inherit;
}

html {
  font-size: 18px;

  @media (max-width: $breakpoint-smaller) {
    font-size: 16px;
  }

}

h1 {
  color: $color-white;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.05rem;
  margin-bottom: 5px;
  padding: 0 15px;
  text-align: center;
}

#maincontent-subpage h1 {
  text-align: inherit;
  color: $color-black;
  padding: 0;
}

h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.05rem;
  margin-bottom: 5px;
}

h3 {
  color: $color-secondary;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.3;
  margin: 0 4% 4% 0;

  @media (max-width: $breakpoint-large) {
      font-size: 1.7rem;
  }

  @media (max-width: $breakpoint-medium) {
      font-size: 1.6rem;
  }

  @media (max-width: $breakpoint-small) {
      font-size: 1.5rem;
  }

  @media (max-width: $breakpoint-smaller) {
      font-size: 1.4rem;
  }

  @media (max-width: $breakpoint-mobile) {
      font-size: 1.3rem;
  }
}

h4 {
  padding: 10px 10px 20px 25px;
}

h5 {
  color: $color-primary;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 1.1rem;
}
