#gallery {

  .gallery-container {

    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .gallery-item {
      flex-basis: 250px;
      flex-grow: 1;
      margin: 0 20px 20px 0;

      img {
        border: 10px solid $color-white;
      }

    }

  }

}