*, *:before, *:after {
  box-sizing: border-box;
}

// margin-classes

.margin-right {
  margin-right: 8px;

  @media (max-width: 1320px){
    display: none;
  }
}

.margin-top {
  margin-top: 20px;

  @media (max-width: $breakpoint-small){
   margin-top: 10px;
  }
}


.svg-square {
  width: 200px;
  height: auto;

  @media (max-width: $breakpoint-medium) {
    width: 160px;
    height: auto;
  }

  @media (max-width: $breakpoint-small) {
    width: 140px;
    margin-top: 5%;
  }

  @media (max-width: $breakpoint-smallest) {
    width: 100px;
    margin-top: 5%;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 90px;
    height: auto;
  }
}

.svg-square-big {
  width: 200px;
  height: auto;

  @media (max-width: $breakpoint-medium) {
    width: 160px;
    height: auto;
  }

  @media (max-width: $breakpoint-small) {
    width: 140px;
    margin-top: 5%;
  }
}

.svg-square-mobile-hidden {
  width: 200px;
  height: auto;

  @media (max-width: $breakpoint-medium) {
    width: 160px;
    height: auto;
  }

  @media (max-width: $breakpoint-small) {
    width: 140px;
    margin-top: 5%;
  }

  @media (max-width: $breakpoint-smallest) {
    display: none;
  }
}

img {
  width: 100%;
  height: auto;
}


.img-box {
  position: relative;

  img:first-child {
    width: 100%;
    height: auto;
  }
}

// offset right - wrapper padding
.trenner-heading-white {
  margin-right: 30px;
}

.trenner-heading,
.trenner-heading {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  color: $color_white;
  background-color: $color-primary;
  margin-top: 40px;
  margin-bottom: -100px;

  @media (max-width: $breakpoint-medium) {
    margin-bottom: -80px;
  }

  @media (max-width: $breakpoint-smaller) {
    margin-bottom: -100px;
  }



  h4 {
    font-size: 1.4rem;

    @media (max-width: $breakpoint-medium) {
      font-size: 1.2rem;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 1.1rem;
    }
  }
}

// offset - outline / border
.inner-gold,
.inner-white {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -12px;
  right: -12px;
  z-index: 10;
  content: '';
}

.inner-white {
  outline: 3px solid $color-white;
}

.inner-gold {
  outline: 3px solid $color-secondary;
}

.z-index-relative {
  position: relative;
  z-index: 100;
}