.zierbalken-header-left,
.zierbalken-header-right {
  position: absolute;
  width: calc((100vw - 1200px) / 2);
  height: 20px;
  background-color: $color-primary;

  @media (max-width: $breakpoint-large) {
    width: 7.5%;
  }

  @media (max-width: $breakpoint-medium) {
    width: 5%;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 2.5%;
  }
}

.zierbalken-section-left {
  position: absolute;
  width: calc((100vw - 1200px - 70px) / 2);
  margin-top: 5px;
  height: 20px;
  background-color: $color-primary;

  @media (max-width: $breakpoint-large) {
    width: 7.5%;
  }

  @media (max-width: $breakpoint-medium) {
    width: 5%;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 2.5%;
  }
}

.zierbalken-header-left,
.zierbalken-section-left {
  left: 0;
}

.zierbalken-header-right,
.zierbalken-section-right {
  right: 0;
}