main section,
.section-confirm,
.section-legal {
  padding: 4% 0;

  @media (max-width: $breakpoint-smaller) {
    padding: 10% 0;

  }
}

.color-section {
  background-color: $color-secondary-pale;
}

section:last-child {
  padding-bottom: 10%;
}