#highlights {

  .second-wrap {
    position: relative;
  }

  ul {
    list-style-image: url('../img/1.png');
    list-style-position: inherit;
    padding-left: 1.4em;

    li {
      margin: 2% 0;
    }
  }

  .button {
    margin: 3% 0;
  }

}