.button {
  display: flex;
  width: 200px;
  height: 70px;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  background-color: inherit;

  p {
    text-align: center;
    line-height: 1;
  }
}

.button-primary {
  border: 8px solid $color-primary;
  color: $color-primary;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out;

  &:hover {
    border: 8px solid $color-secondary;
    color: $color-secondary;
  }
}

.button-secondary {
  border: 8px solid $color-white;
  color: $color-white;
}

.sub-nav-button {
  margin: 1%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;


  @media (max-width: $breakpoint-medium) {
    margin: 1% 5%;
  }

  @media (max-width: $breakpoint-smaller) {
    margin: 1% 2%;
  }
}