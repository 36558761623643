.to-top-arrow-container {
  position: fixed;
  bottom: 25px;
  right: 25px;

  height: 50px;
  width: 50px;
  background-color: $color-primary;

  text-align: center;
  z-index: 200;

  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }


  img {
    padding: 12px 10px;
    vertical-align: middle;

    max-height: 50px;
    max-width: 50px;
  }

  @media (max-width: $breakpoint-smaller) {
    bottom: 15px;
    right: 15px;

    height: 40px;
    width: 40px;

    img {
      max-height: 40px;
      max-width: 40px;
    }
  }
}